.mainSection {
  display: flex;
}

.mainLeft {
  flex: 80%;
}

.mainRight {
  flex: 20%;
}

.weAreBox {
  display: block;
  padding: 2em 2em 0 2em;
  font-size: 3ch;
  text-align: center;
}

/* .discoverBtn {
  display: block;
  font-size: 0.5em;
  height: auto;
  width: max-content;
  margin: 2em auto;
  padding: 6px;
  border: 1px solid black;
  background: none;
  border-radius: 5px;
  text-decoration: none;
  color: inherit;
} */

.weDoBox {
  font-size: 2ch;
  background: rgb(244, 245, 247);
  padding: 3em;
  border-radius: 10px;
}

.meetupBox {
  margin: 5em auto 2em auto;
}

.meetupBox > h2 {
  text-align: center;
}

.meetupBoxCard {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.meetupBoxCard > div {
  max-width: 350px;
  background-color: rgb(244, 245, 247);
  border-radius: 10px;
  margin: 30px auto;
  width: 300px;
  height: 400px;
  border-radius: 40px;
  box-shadow: 5px 5px 20px 7px rgb(0 0 0 / 0%), 0px 0px 0px 5px rgb(0 0 0 / 10%);
  transition: 0.4s;
}

.meetupBoxCard > div:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.meetupBoxCard > div > button {
  border: 1px solid black;
  border-radius: 25px;
  font-weight: bold;
  position: relative;
  top: 85%;
  left: 34%;
  padding: 10px;
  cursor: pointer;
  color: white;
  background: black;
}

.meetupBoxCard1 {
  background-image: url("https://img.freepik.com/free-vector/diversity-tree-hands_23-2147505142.jpg?size=338&ext=jpg&ga=GA1.1.1475863731.1673018765");
  background-size: contain;
  background-repeat: no-repeat;
}

.meetupBoxCard2 {
  background-image: url("https://img.freepik.com/free-vector/diversity-tree-hands_23-2147505142.jpg?size=338&ext=jpg&ga=GA1.1.1475863731.1673018765");
  background-size: contain;
  background-repeat: no-repeat;
}

.meetupBoxCard3 {
  background-image: url("https://img.freepik.com/free-vector/diversity-tree-hands_23-2147505142.jpg?size=338&ext=jpg&ga=GA1.1.1475863731.1673018765");
  background-size: contain;
  background-repeat: no-repeat;
}

.empathyCard > h1 {
  text-align: center;
}

.empathyCard {
  font-size: 2ch;
  padding: 2em 3em;
  border-radius: 10px;
  text-align: center;
  margin: auto;
}

