/* Add my Custom CSS Reset Code Starts Here */

/* Add my Custom CSS Reset Code Ends Here */

/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");

body {
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
}

.horLineStyle {
  width: 85%;
  border: 0.25px solid lightgrey;
}
.app {
  display: block;
}
