.mainBody {
  margin: auto;
  width: 85%;
}

.horLineStyle {
  width: 100%;
  border: 0.25px solid lightgrey;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navTab > * {
  text-decoration: none;
  padding: 10px;
  color: black;
}

/* .socialIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
} */

.socialIcon > * {
  margin: 0px 5px;
}
