.footerContainer {
  margin: auto;
  width: 85%;
  padding: 10px;
  background-color: #f3f5f7;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.logo {
  height: 500px;
  width: 500px;
}

.footermenu {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;
  justify-content: center;
  align-items: center;
}
.footermenu a {
  color: black;
  text-decoration: none;
}

.footersection {
  flex-shrink: 1;
  padding: 10px;
}
