.aboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horStyle {
  border-top: 5px dotted darkgrey;
  border-bottom: none;
  width: 15%;
  margin: 15px 0px;
}

.aboutImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 600px;
}

.aboutImage > img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.content {
  max-width: 600px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10%;
  margin: 5% auto;
}

.founderCard {
  height: 350px;
  width: 300px;
  background: #f2f2f2;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 20px 8px #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.founderCard > img {
  width: 200px;
  border-radius: 50%;
  margin-top: 2em;
}
